import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import {
    layout,
    content,
    auth,
    authPhoto,
    authImage,
    authColumn,
    titleText,
    contactLink,
    contactButton,
    authQuote,
} from "./auth-layout.module.scss";
import TextSVG from "../assets/images/svg/modatoty-white.svg";
import { ISEOProps } from "../components/seo";

import MainLayout from "./main-layout";
import SectionWrapper from "../components/molecules/section-wrapper";
import Section from "../components/molecules/section";
import Link from "../components/atoms/link";
import Title, { ITitleProps } from "../components/atoms/title";
import Quote from "../components/molecules/quote";

export interface IAuthProps {
    className?: string;
    contentClassName?: string;
    title?: string;
    titleAlign?: ITitleProps["align"];
    titleSize?: ITitleProps["size"];
    SEOProps?: ISEOProps;
}

const AuthLayout: React.FC<IAuthProps> = ({
    children,
    className,
    contentClassName,
    title,
    titleAlign,
    titleSize = "large",
    SEOProps = {},
}) => {
    const { loginImageData } = useStaticQuery(query);
    const loginImage = getImage(loginImageData);

    return (
        <MainLayout
            className={layout}
            SEOProps={{
                title: title,
                ...SEOProps,
            }}
        >
            <SectionWrapper className={auth}>
                <div className={authPhoto}>
                    <Quote className={authQuote} text={"\"Moda to Ty\""} subtext={"Fashion Fairy"} ></Quote>
                </div>
                {/* <Section
                    className={authPhoto}
                    column="full-left"
                    columnTablet="full"
                    columnPhone="full"
                >
                    {loginImage && <GatsbyImage alt="" image={loginImage} className={authImage} />}
                    
                </Section> */}
                <Section
                    className={authColumn}
                    column="full-right"
                    columnTablet="full"
                    columnPhone="regular"
                >
                    <main className={className}>
                        {title && (
                            <Title
                                size={titleSize}
                                className={titleText}
                                fontStyle="normal"
                                align={titleAlign}
                            >
                                <span>{title}</span>
                            </Title>
                        )}
                        <div className={`${content} ${contentClassName}`}>
                            {children}
                            <div className={contactLink}>
                                Potrzebujesz pomocy?
                                <Link to="/kontakt/" className={contactButton}>
                                    Napisz do nas!
                                </Link>
                            </div>
                        </div>
                    </main>
                </Section>
            </SectionWrapper>
        </MainLayout>
    );
};

const query = graphql`
    query {
        loginImageData: file(relativePath: { eq: "loginPhoto.jpg" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 95, layout: FULL_WIDTH)
            }
        }
    }
`;

export default AuthLayout;
