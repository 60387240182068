// extracted by mini-css-extract-plugin
export var auth = "auth-layout-module--auth--vpVFV";
export var authColumn = "auth-layout-module--auth-column--h3AXJ";
export var authImage = "auth-layout-module--auth-image--Es0Qq";
export var authPhoto = "auth-layout-module--auth-photo---Hd3k";
export var authQuote = "auth-layout-module--auth-quote--bM7JJ";
export var contactButton = "auth-layout-module--contact-button--8ynDy";
export var contactLink = "auth-layout-module--contact-link--jl-9Q";
export var content = "auth-layout-module--content--wrDw8";
export var layout = "auth-layout-module--layout--u6aZE";
export var titleText = "auth-layout-module--title-text--tNvv1";